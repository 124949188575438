define('application/menu',[],function () {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      var $body = $("body");

      data.dom.find(".js-menu-open").on("click", function () {
        $body.toggleClass("js-menu-active");
      });

      data.dom.find(".js-menu-close").on("click", function () {
        $body.toggleClass("js-menu-active");
      });

      data.dom.find(".js-menu").on("click", "a", function () {
        $body.toggleClass("js-menu-active");
      });
    });

    return this;
  }
});
