require.config({
  baseUrl: "/main/js",
  paths: {
    //"propertyParser": "../../bower_components/requirejs-plugins/src/propertyParser",
    //"async": "../../bower_components/requirejs-plugins/src/async",
    //"goog": "../../bower_components/requirejs-plugins/src/goog",
    "jquery": "vendor/jquery/3.3.1/jquery.min",
    "owl-carousel": "vendor/owlcarousel/2.3.4/owl.carousel.min",
    "js-cookie": "vendor/jscookie/2.2.0/js.cookie.min",
    "picture-polyfill": "vendor/picturepolyfill/4.2.0/picturePolyfill.min"
  },
  shim: {
    "jquery": {
      exports: "jQuery"
    },
    "owl-carousel": ["jquery"]
  }
});

require(["application/main"]);
define("main", function(){});

