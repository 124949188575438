define('application/main',[
  "application/options",
  "i18n!nls/text",
  "jquery",
  "picture-polyfill",
  "application/moveToFragment",
  "application/menu",
  "application/carousel",
  "application/opacityOnScroll",
  "application/map"
], function (appOptions,
             text,
             $,
             picturePolyfill,
             appMoveToFragment,
             appMenu,
             appCarousel,
             appOpacityOnScroll,
             appMap) {
  var $window = $(window);
  var $document = $(document);
  var $html = $("html");
  var $body = $("body");

  var locale = $html.attr("lang");

  appMoveToFragment($document);
  appMenu($document);
  appCarousel($document);
  appOpacityOnScroll($document);
  appMap($document);

  $document.trigger("app.domset", [{dom: $body}]);

  // run it when the page is already scrolled on refresh so the events based on scrolling can fire at load
  $window.trigger("scroll");

  // run it when the page is loaded so the events based on resizing can fire at load
  $window.trigger("resize");
});
