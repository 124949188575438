define('application/carousel',["application/options", "owl-carousel"], function (appOptions) {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-owl-carousel").each(function () {
        var $this = $(this);
        var $parent = $this.parent();
        var $navigationPrevious = $parent.find(".js-owl-carousel-previous");
        var $navigationNext = $parent.find(".js-owl-carousel-next");

        $this.owlCarousel($.extend({}, appOptions.owlCarousel, $this.data("owl-carousel")));

        /*$navigationPrevious.on("click", function () {
          $this.find(".owl-prev").trigger("click");
        });
        $navigationNext.on("click", function () {
          $this.find(".owl-next").trigger("click");
        });*/

        /*if (!$this.data("owl-carousel").loop) {
          $this.on("initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel", function (item) {
            if (!item.namespace) {
              return;
            }

            var carousel = item.relatedTarget;
            var current = carousel.current();

            if (current === carousel.maximum()) {
              $navigationNext.fadeTo(0, .4).css("pointer-events", "none");
            } else if (current === carousel.minimum()) {
              $navigationPrevious.fadeTo(0, .4).css("pointer-events", "none");
            } else {
              $navigationPrevious.fadeTo(200, 1).css("pointer-events", "all");
              $navigationNext.fadeTo(200, 1).css("pointer-events", "all");
            }
          });

          $navigationPrevious.fadeTo(0, .4).css("pointer-events", "none");
        }*/
      });
    });

    return this;
  }
});
