define('application/opacityOnScroll',["jquery"], function ($) {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-scroll").each(function () {
        var $this = $(this);
        var targetHeight = $this.outerHeight();

        $document.on("scroll", function () {
          var scrollPercent = (window.scrollY) / targetHeight;

          if(scrollPercent >= 0){
            $this.css("opacity", scrollPercent / 2);
          }
        });
      });
    });

    return this;
  }
});
